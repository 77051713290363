import { createContext } from 'react';
import { UserProfile as Auth0User } from '@auth0/nextjs-auth0/client';
import { Auth0Role } from '@/api/graphql';

export type AuthenticationContextType = {
  user?: Auth0User;
  isRegisteredViaSocialProvider: boolean;
  isAuthenticated: boolean;
  claims?: {
    organizationId: string;
    userId: string;
    auth0Role: Auth0Role;
    logging?: boolean;
  };
  jwtToken?: string;
  auth0OrgId?: string;
  loggingEnabled: boolean;
};

export const initialValue: AuthenticationContextType = {
  isRegisteredViaSocialProvider: false,
  isAuthenticated: false,
  jwtToken: undefined,
  auth0OrgId: undefined,
  loggingEnabled: false,
};

export const AuthenticationContext =
  createContext<AuthenticationContextType>(initialValue);
